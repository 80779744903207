import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Modal from "react-modal";
import Users from "./Users";

import spinner from "../images/loader.gif";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    OverflowY: "auto",
    maxHeight: "80vh",
    width: "70%",
    maxWidth: "735px",
  },
};
Modal.setAppElement("#root");
const IMPORT_URL = "/api/admin/import";
const AdminHeader = () => {
  const [user, setUser] = useState();
  const [types, setTypes] = useState();
  const [partenaires, setPartenaires] = useState();
  const [type, setType] = useState("");
  const [partenaire, setPartenaire] = useState("");
  const [etat, setEtat] = useState("");
  const [nom, setNom] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [typef, setTypeF] = useState("");
  const [partenairef, setPartenaireF] = useState("");
  const [codePostalf, setCodePostalF] = useState("");
  const [nomf, setNomF] = useState("");
  const [users, setUsers] = useState([]);
  const [doc, setDoc] = useState();
  const [separator, setSeparator] = useState(",");
  const [updated, setUpdated] = useState();
  const [saved, setSaved] = useState();
  const [missing, setMissing] = useState();
  const [listMissing, setListMissing] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [showBtn, setShowBtn] = useState(false);
  const [activeBtn, setActiveBtn] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleReset = async () => {
    /* console.log("rafraichir") */
    setNom("");
    setPartenaire("");
    setEtat("");
    setType("");
    setCodePostal("");
    setNomF("");
    setCodePostalF("");
    setPartenaireF("");
    setTypeF("");
    let isMounted = true;

    try {
      const response = await axiosPrivate.get(`/api/admin/client`);
      /*  console.log(response) */
      if (isMounted) {
        setUsers(response?.data?.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  /* const handleSubmit = async (e) => {
    e.preventDefault();
    setShowBtn(true);
    setActiveBtn("disabled");
    const formData = new FormData();
    formData.append("csv", doc);
    formData.append("separator", separator);
    console.log(separator);
    try {
      const response = await axiosPrivate.post(IMPORT_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(JSON.stringify(response?.data?.data?.contracts));
      // Créez une référence pour l'identifiant de l'intervalle
      const intervalId = setInterval(async () => {
        try {
          const response = await axiosPrivate.get("/api/admin/upload/status");
          if (response?.data?.status == true) {
            getUsers();
            setSuccessMsg("Chargement effectué");
            setListMissing(response?.data?.data?.missing);
            setUpdated(response?.data?.data?.total_updates);
            setSaved(response?.data?.data?.total_saves);
            setMissing(response?.data?.data?.total_missing);
            setDoc(null);
            setShowBtn(false);
            setActiveBtn("");

            // Arrêtez l'intervalle si le statut est vrai
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération du statut de l'upload",
            error
          );
        }
      }, 1000);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Erreur de chargement");
      }

      const intervalId = setInterval(async () => {
        try {
          const response = await axiosPrivate.get("/api/admin/upload/status");
          if (response?.data?.status == true) {
            getUsers();
            setSuccessMsg("Chargement effectué");
            setListMissing(response?.data?.data?.missing);
            setUpdated(response?.data?.data?.total_updates);
            setSaved(response?.data?.data?.total_saves);
            setMissing(response?.data?.data?.total_missing);
            setDoc(null);
            setShowBtn(false);
            setActiveBtn("");

            // Arrêtez l'intervalle si le statut est vrai
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération du statut de l'upload",
            error
          );
        }
      }, 1000);
    }
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowBtn(true);
    setActiveBtn("disabled");
  
    const formData = new FormData();
    formData.append("csv", doc);
    formData.append("separator", separator);
  
    try {
      await axiosPrivate.post(IMPORT_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      // Démarrer la vérification de statut après l'envoi des données
      checkUploadStatus();
    } catch (err) {
      handleError(err);
      checkUploadStatus(); // Démarrer la vérification de statut même en cas d'erreur
    }
  };
  
  const checkUploadStatus = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axiosPrivate.get("/api/admin/upload/status");
        const status = response?.data?.status;
  
        if (status === 'finished') {
          handleSuccess(response.data);
          clearInterval(intervalId);
        } else if (status === 'failed') {
          handleError(new Error("Upload failed"));
          clearInterval(intervalId);
        }
        // Si le statut est 'running', continuer à vérifier
  
      } catch (error) {
        console.error("Erreur lors de la récupération du statut de l'upload", error);
        clearInterval(intervalId);
      }
    }, 1000);
  };
  
  const handleSuccess = (data) => {
    getUsers();
    setSuccessMsg("Chargement effectué");
    setListMissing(data?.data?.missing);
    setUpdated(data?.data?.total_updates);
    setSaved(data?.data?.total_saves);
    setMissing(data?.data?.total_missing);
    setDoc(null);
    setShowBtn(false);
    setActiveBtn("");
  };
  
  const handleError = (err) => {
    if (!err?.response) {
      setErrMsg("No Server Response");
    } else if (err.response?.status === 400) {
      setErrMsg("Missing Username or Password");
    } else if (err.response?.status === 401) {
      setErrMsg("Unauthorized");
    } else {
      setErrMsg("Erreur de chargement");
    }
  };
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get("/api/admin/client");
      /* console.log(response.data); */
      setUsers(response?.data?.data);
      setTypes(response?.data?.types);
      setPartenaires(response?.data?.partenaires);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleSubmitFilter = async (e) => {
    e.preventDefault();
    let isMounted = true;
    /* console.log("filtrons", nom, type, partenaire, users) */
    try {
      const response = await axiosPrivate.get(
        `/api/admin/client/?${nom ? "nom=" + nom : ""}&${
          codePostal ? "code=" + codePostal : ""
        }&${type ? "type=" + type : ""}&${
          partenaire ? "partenaire=" + partenaire : ""
        }&${etat ? "etat=" + etat : ""}`
      );
      /*  console.log(response) */
      if (isMounted) {
        setUsers(response?.data?.data);
        setTypes(response?.data?.types);
        setPartenaires(response?.data?.partenaires);
        setNomF(nom);
        setTypeF(type);
        setPartenaireF(partenaire);
        setCodePostalF(codePostal);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function openModal() {
    setDoc(null);
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setSuccessMsg("");
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/api/client/profil");
        /* console.log(response.data); */
        isMounted && setUser(response.data.data);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
      try {
        const response = await axiosPrivate.get("/api/admin/client");
        /* console.log(response.data); */
        if (isMounted) {
          setUsers(response?.data?.data);
          setTypes(response?.data?.types);
          setPartenaires(response?.data?.partenaires);
        }
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <>
      <div className="container">
        <div className="welcome">
          <h3 className="hh">
            Bienvenue, {user?.nom} {user?.prenom}
          </h3>
        </div>
        <div className="buttons">
          <button onClick={openModal} className="btn1">
            <i class="fa-solid fa-file-import"></i> Import redox
          </button>
        </div>
      </div>
      <div className="container-header">
        <section className="filtre">
          <h2>Recherche</h2>

          <div className="container-form search-users">
            <form className="column" onSubmit={handleSubmitFilter}>
              <input
                type="text"
                value={nom}
                placeholder="Nom / Prénom"
                id="firstname"
                name="firstname"
                onChange={(e) => setNom(e.target.value)}
              />

              <select
                value={type}
                id="select2"
                name="select2"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <option value="">Contrat</option>
                {types &&
                  types?.map((item) => (
                    <option key={item?.id} value={item?.type}>
                      {item?.type}
                    </option>
                  ))}
              </select>

              <select
                value={partenaire}
                id="select3"
                name="select3"
                onChange={(e) => {
                  setPartenaire(e.target.value);
                }}
              >
                <option value="">Assureur/partenaire</option>
                {partenaires &&
                  partenaires?.map((item) => (
                    <option key={item?.id} value={item?.partenaire}>
                      {item?.partenaire}
                    </option>
                  ))}
              </select>

              <select
                value={etat}
                id="select3"
                name="select3"
                onChange={(e) => {
                  setEtat(e.target.value);
                }}
              >
                <option value="">État</option>
                <option value="attente">En attente</option>
                <option value="new">Nouveau</option>
                <option value="active">Activé</option>
              </select>

              <input
                type="text"
                value={codePostal}
                placeholder="Code postal"
                id="code-postal"
                name="code-postal"
                onChange={(e) => setCodePostal(e.target.value)}
              />
              <button type="submit" className="custom-btn btn-7">
                <span>
                  {" "}
                  <i className="fa-sharp fa-solid fa-magnifying-glass"></i>{" "}
                  Rechercher
                </span>
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="custom-btn btn-7"
              >
                <span>
                  {" "}
                  <i className="fa-sharp fa-solid fa-arrows-rotate"></i>{" "}
                  Réinitialiser
                </span>
              </button>
            </form>
          </div>
        </section>
      </div>

      {/* {successMsg ? <><p>{successMsg}</p> <ul><li>Nouveaux : {saved}</li><li>A jours : {updated}</li><li>Erroné : {missing}</li><li>Lignes erroné : {listMissing?.map((item) => {
                    return item + ", "
                })}</li></ul></> : ''} */}

      <Users
        users={users}
        nom={nomf}
        type={typef}
        partenaire={partenairef}
        codePostal={codePostalf}
      />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Charger un document
          </h2>
          <button className="close" onClick={closeModal}>
            X
          </button>
        </div>
        {errMsg ? errMsg : ""}
        {successMsg ? (
          <>
            <p>{successMsg}</p>{" "}
            <ul>
              <li>Nouveaux : {saved}</li>
              <li>à jours : {updated}</li>
              <li>Erroné : {missing}</li>
              <li>
                Lignes erroné :{" "}
                {listMissing?.map((item) => {
                  return item + ", ";
                })}
              </li>
            </ul>
          </>
        ) : (
          <form className="formRegister modal-document" onSubmit={handleSubmit}>
            <label>
              Séparateur:
              <div className="">
                <select
                  required
                  onChange={(e) => {
                    setSeparator(e.target.value);
                  }}
                >
                  <option value=",">,</option>
                  <option value=";">;</option>
                  <option value="|">|</option>
                </select>
              </div>
            </label>
            <label>
              Document format CSV ou Excel:
              <div className="">
                <input
                  type="file"
                  id="doc"
                  name="doc"
                  onChange={(e) => setDoc(e.target.files[0])}
                  defaultValue={doc}
                  autoComplete="off"
                  accept="application/csv, application/txt"
                  required
                />
              </div>
            </label>

            {showBtn ? (
              <div className="spinner-check">
                {" "}
                <img src={spinner} alt="logo2" />
              </div>
            ) : (
              <button disabled={activeBtn} type="submit">
                Charger
              </button>
            )}
          </form>
        )}
      </Modal>
    </>
  );
};

export default AdminHeader;



import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import $ from 'jquery';
import { axiosPrivate } from "../api/axios";

const Users = ({ users, nom, type, partenaire, codePostal }) => {
    const makeBold = (item, keyword) => {
        const re = new RegExp(keyword, "g");
        return item?.replace(re, "<b class='red-txt'>" + keyword + "</b>");
    };

    const handleReset = async (id) => {
        try {
            // setInvited(current => [...current, id])

            const response = await axiosPrivate.post('/api/admin/client/invite', JSON.stringify({ id: id }),
                {
                    headers: { 'Content-Type': 'application/json' },

                });
            console.log(response.data);
            if (response.data.code == 203) {
                setInvited(current => [...current, response.data.id])
                setInvitedCp(current => [...current, response.data.id])
                invitedError.filter(item => item !== id)
                setTimeout(() => {
                    setInvited([])
                }, 10000);
            }
            else {
                setInvitedError(current => [...current, id])
                invited.filter(item => item !== id)
                invitedCp.filter(item => item !== id)
                console.log(invitedError.includes(id))
                setTimeout(() => {
                    setInvitedError([])
                }, 10000);
            }
            //isMounted && setContrats(response?.data?.data);
        } catch (err) {
            console.error(err);
            setInvitedError(current => [...current, id])
            invited.filter(item => item !== id)
            invitedCp.filter(item => item !== id)
            setTimeout(() => {
                setInvitedError([])
            }, 10000);
            //navigate('/login', { state: { from: location }, replace: true });
        }
    };
    const [currentPage, setCurrentPage] = useState(0)
    const [invited, setInvited] = useState([])
    const [invitedCp, setInvitedCp] = useState([])
    const [invitedError, setInvitedError] = useState([])
    const PER_PAGE = 5;
    const offset = currentPage * PER_PAGE;
    const currentPageData = users
        ?.slice(offset, offset + PER_PAGE)
        ?.map((item) => (
            <tr key={item?.id}>
                <td dangerouslySetInnerHTML={{ __html: makeBold(item?.nom + " " + item?.prenom, nom) }}></td>
                <td dangerouslySetInnerHTML={{ __html: makeBold(item?.codePostal , codePostal) }}></td>
                <td className="break" dangerouslySetInnerHTML={{ __html: item?.assureur?.sort((a, b) => a.toLowerCase().startsWith(partenaire.toLowerCase()) < b.toLowerCase().startsWith(partenaire.toLowerCase()) ? 1 : -1).map((assureur, index) => { return (index ? ' ' : '') + makeBold(assureur, partenaire) }) }}></td>
                <td className="break" dangerouslySetInnerHTML={{ __html: item?.typeAssurance?.sort((a, b) => a.toLowerCase().startsWith(type.toLowerCase()) < b.toLowerCase().startsWith(type.toLowerCase()) ? 1 : -1).map((assureur, index) => { return (index ? ' ' : '') + makeBold(assureur, type) }) }}></td>
                <td>  {item?.invited == false && !invitedCp.includes(item?.id) ? <span className="new">nouveau</span> : (item?.invited == true || invitedCp.includes(item?.id)) && item?.active == false ? <span className="en_attente">en attente</span> : <span className="active">activé</span>}</td>
                <td><Link to={"profil/client/" + item?.id} className="detail">Fiche client</Link>  {item?.invited == false && !invited.includes(item?.id) && !invitedError.includes(item?.id) && !invitedCp.includes(item?.id)  ? <a onClick={() => handleReset(item?.id)} href="#0" className="inviter">Inviter</a> : invitedError.includes(item?.id) ? <><i className="fa-solid fa-times red" ></i> </> : invited.includes(item?.id) && <i className="fa-solid fa-check  green green-check" ></i>}
                {(item?.invited == true || invitedCp.includes(item?.id)) && item?.active == false && !invitedError.includes(item?.id) &&  <a onClick={() => handleReset(item?.id)} href="#0" className="inviter">Réenvoyer</a>}

                </td>
            </tr>
        ));
    const pageCount = Math.ceil(users.length / PER_PAGE);
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);

    }
    useEffect(() => {
        console.log("Users", users)
        setTimeout(() => {
            $(".pagination li:nth-child(2) a")[0].click();

        }, 100);
        handlePageClick({ selected: 0 })
        setCurrentPage(0)

    }, [users])
    return (
        <>

            <div className="container-header table-admin">
                <table className='tableau'>
                    <thead>
                        <tr>
                            <th>Nom Prénom</th>
                            <th>Code postal</th>
                            <th>Nom assureur</th>
                            <th>Type d'assurance</th>
                            <th>État</th>
                            <th>Détail client</th>
                        </tr>
                    </thead>
                    <tbody>

                        {currentPageData}

                    </tbody>
                </table>
            </div>
            <ReactPaginate
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
        </>

    );
};

export default Users;

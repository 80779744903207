import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import axios from '../api/axios';
import NewPassword from './newPassword/NewPassword';
const RESET_URL = '/api/reset-password/reset/token';
const RESET_VERIFY = '/api/reset-password/verify';

const ResetSMS = () => {

    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();
    const params = useParams();
    const [otp, setOtp] = useState('');
    const [user, setUser] = useState('');
    const [validToken, setValidToken] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    
  
  

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [otp])

    const handleSubmit = async (e) => {

        e.preventDefault();
        
            try {
                const response = await axios.post(RESET_VERIFY,
                    JSON.stringify({ otp: otp, user }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        //withCredentials: true
                    }
                );
                /* console.log(JSON.stringify(response?.data)); */
                if(response?.data?.message)
                {
                    
                    setSuccessMsg("un mail de réinitialisation de mot de mot de passe a été envoyé");
                    setOtp("");
                    navigate("/new/password", {
                        state: {
                            id: user
                        },
                    });
                }

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Erreur de connexion au serveur');
                } else if (err.response?.status === 400) {
                    setErrMsg('Code incorect ou expiré ');
                } else if (err.response?.status === 401) {
                    setErrMsg('Adresse email invalide');
                } else {
                    setErrMsg('Code incorect ou expiré');
                }
                errRef.current.focus();
            }

    }

    const codeResend = async() =>{
        if (params.id != undefined)
        try {
            const response = await axios.post(RESET_URL,
                JSON.stringify({ token: params.id }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    
                }
            );
           /*  console.log(JSON.stringify(response?.data)); */
           setValidToken(true);
            setUser(response?.data?.user)
            

        } catch (err) {
            if (!err?.response) {
                setErrMsg('Erreur de connexion au serveur');
            } else if (err.response?.status === 400) {
                setErrMsg('Code incorect ou expiré');
            } else if (err.response?.status === 401) {
                setErrMsg('Adresse email invalide');
            } else {
                setErrMsg('Erreur serveur');
            }
            errRef.current.focus();
        }

    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            
            if (params.id != undefined)
            try {
                const response = await axios.post(RESET_URL,
                    JSON.stringify({ token: params.id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        
                    }
                );
                /* console.log(JSON.stringify(response?.data)); */
                isMounted && setValidToken(true);
                setUser(response?.data?.user)
                

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Erreur de connexion au serveur');
                } else if (err.response?.status === 400) {
                    setErrMsg('Code incorect ou expiré ');
                } else if (err.response?.status === 401) {
                    setErrMsg('Adresse email invalide');
                } else {
                    setErrMsg('Erreur serveur');
                }
                errRef.current.focus();
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    

    

    return (

        <>
            <div className="form-container">


                <div className="logo-connexion">
                    

                    <h1>Validation code OTP</h1>
                    <p>Merci de saisir le code reçu par SMS, ce code expire dans 10 minutes</p>

                    {errMsg ? <p className='error'>{errMsg}</p> : ""}
                </div>


                <form className="formRegister" onSubmit={handleSubmit}>
                    <label>
                        Code OTP:
                        <div className="">
                            <input type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                                required />
                        </div>

                    </label>
                    
                    
                    <button type="submit" >Envoyer</button>
                    {successMsg ? <p className='success'>{successMsg}</p> : ""}
                </form>
                <a onClick={codeResend} role="button" tabIndex="0">Vous n'avez pas reçu de code? renvoyer</a>
            </div>
        </>

    )
}

export default ResetSMS

import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import logo3 from "../Logos/icon-i.png";
import ReactPaginate from "react-paginate";
import spinner from "../images/loader.gif";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    OverflowY: "auto",
    maxHeight: "80vh",
    width: "70%",
    maxWidth: "735px",
  },
};
Modal.setAppElement("#root");
const IMPORT_URL = "/api/admin/document";
const REMOVE_URL = "/api/admin/document/remove";
const ProfilClient = () => {
  const [users, setUsers] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [contrats, setContrats] = useState();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const location = useLocation();
  const params = useParams();
  const [docs, setDocs] = useState([]);
  const [contrat, setContrat] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [titre, setTitre] = useState("");
  const [texte, setTexte] = useState("Modifier");
  const [email, setEmail] = useState("");
  const [telephone, setTelepone] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [successMsgRem, setSuccessMsgRem] = useState("");
  const [doc, setDoc] = useState();
  const [showBtn, setShowBtn] = useState(false);
  const [readonly, setReadOnly] = useState(true);
  const [activeBtn, setActiveBtn] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  const edit = (e) => {
    e.preventDefault();
    if (texte == "Modifier") {
      setReadOnly(false);
      setTexte("Enregister");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "Êtes vous sur de vouloir changer cette information client ? (Si oui, merci de le faire aussi dans REDOX)",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axiosPrivate.post(
              "/api/admin/edit/profil",
              JSON.stringify({ id: users.id, email, telephone }),
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            if (response.data.status != false) {
                getUsers()
                setTexte("Modifier")
                setReadOnly(true)
              Swal.fire({
                title: "Modification!",
                text: "Les informations de l'utilisateur sont à jour.",
                icon: "success",
              });

            } else {
              Swal.fire({
                title: "Erreur!",
                text: response.data.message,
                icon: "error",
              });
            }
          } catch (err) {
            console.error(err);
            Swal.fire({
              title: "Erreur!",
              text: "Une erreur est survenu au niveau du serveur.",
              icon: "error",
            });
          }
        }
      });
    }
  };

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setSuccessMsg("");
    setErrMsg("");
    setSuccessMsgRem("");
  }

  const downloadFile = (url, title) => {
    const titre = title + ".pdf";
    saveAs(url, titre);
  };

  const removeFile = async (item) => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.post(
        REMOVE_URL,
        JSON.stringify({ id: item.id, contrat: item.contrat }),
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data.code === 203) {
        setSuccessMsgRem("Suppression effectuée");
        /* console.log("results",response?.data) */
        setDocs(response?.data?.docs);
        setErrMsg("");
        setSuccessMsg("");

        const getContrat = async () => {
          try {
            const response = await axiosPrivate.post(
              "/api/admin/client/contrat",
              JSON.stringify({ id: params.id }),
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            /*  console.log(response.data); */
            isMounted && setContrats(response?.data?.data);
          } catch (err) {
            console.error(err);
            navigate("/login", { state: { from: location }, replace: true });
          }
        };

        getContrat();
      } else {
        setErrMsg(response.data.message);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Aucune reponse du serveur");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Loading failed");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowBtn(true);
    setActiveBtn("disabled");
    const formData = new FormData();
    let isMounted = true;
    formData.append("document", doc);
    formData.append("contrat", contrat);
    formData.append("titre", titre);

    try {
      const response = await axiosPrivate.post(IMPORT_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      /*  console.log(JSON.stringify(response?.data)); */
      if (response?.data.code === 203) {
        setSuccessMsg("Chargement effectué");
        /* console.log("results",response?.data) */
        setDocs(response?.data?.docs);
        setErrMsg("");
        setSuccessMsgRem("");
        setDoc(null);
        setTitre("");
        setShowBtn(false);
        setActiveBtn("");
        inputRef.current.value = null;
        const getContrat = async () => {
          try {
            const response = await axiosPrivate.post(
              "/api/admin/client/contrat",
              JSON.stringify({ id: params.id }),
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            /*  console.log(response.data); */
            isMounted && setContrats(response?.data?.data);
          } catch (err) {
            console.error(err);
            navigate("/login", { state: { from: location }, replace: true });
          }
        };

        getContrat();
      } else {
        setErrMsg(response.data.message);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Le chargement du fichier a échoué");
      }
    }
  };
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.post(
        "/api/admin/client/profil",
        JSON.stringify({ id: params.id }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(response.data);
      if (response.data) {
        setUsers(response.data.data);
        setEmail(response.data.data.email);
        setTelepone(response.data.data.telephone);
      }
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (isMounted) getUsers();
    const getContrat = async () => {
      try {
        const response = await axiosPrivate.post(
          "/api/admin/client/contrat",
          JSON.stringify({ id: params.id }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        /*  console.log(response.data); */
        isMounted && setContrats(response?.data?.data);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getContrat();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  const [currentPage, setCurrentPage] = useState(0);
  const [invited, setInvited] = useState([]);
  const [invitedError, setInvitedError] = useState([]);
  const [invitedCp, setInvitedCp] = useState([]);

  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const currentPageData = contrats
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <tr key={item.id}>
        <td className="break">{item.assureur}</td>
        <td className="info-assurance">
          {item.type}{" "}
          <span
            className="info"
            style={{ display: item.description ? "inline-block" : "none" }}
          >
            <img src={logo3} alt="logo2" />
            <span className="description">{item.description}</span>
          </span>
        </td>
        <td>{item.active ? "Actif" : "Inactif"}</td>
        <td>
          <a
            onClick={() => {
              setDocs(item?.document);
              openModal();
              setContrat(item.id);
            }}
            href="#"
            style={{
              color: item?.document?.length > 0 ? "#008000" : "#FF0000",
            }}
          >
            <i
              className="fa fa-download"
              aria-hidden="true"
              style={{
                marginRight: "5px",
                color: item?.document?.length > 0 ? "#008000" : "#FF0000",
              }}
            ></i>
            Téléverser un fichier pdf{" "}
          </a>{" "}
        </td>
      </tr>
    ));
  const pageCount = Math.ceil(contrats?.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleReset = async (id) => {
    try {
      // setInvited(current => [...current, id])

      const response = await axiosPrivate.post(
        "/api/admin/client/invite",
        JSON.stringify({ id: id }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.code == 203) {
        setInvited((current) => [...current, response.data.id]);
        setInvitedCp((current) => [...current, response.data.id]);
        invitedError.filter((item) => item !== id);
        setTimeout(() => {
          setInvited([]);
        }, 10000);
      } else {
        setInvitedError((current) => [...current, id]);
        invited.filter((item) => item !== id);
        invitedCp.filter((item) => item !== id);
        console.log(invitedError.includes(id));
        setTimeout(() => {
          setInvitedError([]);
        }, 10000);
      }
    } catch (err) {
      setInvitedError((current) => [...current, id]);
      invited.filter((item) => item !== id);
      invitedCp.filter((item) => item !== id);
      setTimeout(() => {
        setInvitedError([]);
      }, 10000);
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
    }
  };
  return (
    <>
      <div className="container2">
        <Link to="/admin">
          <button className="custom-btn btn-5">
            <span>
              <i className="topIcon fas fa-arrow-left"></i> Home
            </span>
          </button>
        </Link>
      </div>
      <section className="profil-form">
        <div className="profil-title">
          <h1>Admin ZZ- Profil Client</h1>
          <br />
          {users?.invited == false &&
          !invited.includes(users?.id) &&
          !invitedError.includes(users?.id) &&
          !invitedCp.includes(users?.id) ? (
            <a
              onClick={() => handleReset(users?.id)}
              href="#0"
              className="inviter"
            >
              Inviter
            </a>
          ) : invitedError.includes(users?.id) ? (
            <>
              <i className="fa-solid fa-times red"></i>{" "}
            </>
          ) : (
            invited.includes(users?.id) && (
              <i className="fa-solid fa-check  green green-check"></i>
            )
          )}
        </div>
        <form className="formRegister">
          <label>
            Prénom:
            <div className="">
              <input
                type="text"
                id="prenom"
                autoComplete="off"
                defaultValue={users?.prenom}
                readOnly
                required
              />
            </div>
          </label>
          <label>
            Nom:
            <div className="">
              <input
                type="text"
                id="nom"
                autoComplete="off"
                defaultValue={users?.nom}
                readOnly
                required
              />
            </div>
          </label>
          <label>
            Adresse:
            <div className="">
              <input
                type="text"
                id="adresse"
                autoComplete="off"
                defaultValue={users?.adresse}
                readOnly
                required
              />
            </div>
          </label>
          <label>
            Code postal:
            <div className="">
              <input
                type="text"
                id="postcode"
                autoComplete="off"
                defaultValue={users?.codePostal}
                readOnly
                required
              />
            </div>
          </label>
          <label>
            Téléphone:
            <div className="">
              <input
                type="text"
                id="telephone"
                autoComplete="off"
                onChange={(e) => setTelepone(e.target.value)}
                defaultValue={telephone}
                readOnly={readonly}
                required
              />
            </div>
          </label>
          <label>
            E-mail:
            <div className="">
              <input
                type="email"
                id="username"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                readOnly={readonly}
                defaultValue={email}
                required
              />
            </div>
          </label>

          <button type="submit" onClick={edit}>
            {texte} <i class="fa-regular fa-edit white"></i>
          </button>
        </form>

        <div className="flexGrow"></div>
      </section>

      <div className="container2">
        <section className="contrats">
          <hr />

          <h2>Contrats</h2>
          <table className="tableau">
            <thead>
              <tr>
                <th>Nom assureur</th>
                <th>Type d'assurance</th>
                <th>Etat</th>
                <th>Télécharger</th>
              </tr>
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
          {contrats?.length >= PER_PAGE && (
            <ReactPaginate
              previousLabel={"←"}
              nextLabel={"→"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          )}
        </section>
      </div>

      <div className="modal">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Documents</h2>
            <button className="close" onClick={closeModal}>
              X
            </button>
          </div>
          <div className="formRegister">
            <h4>Liste des documents</h4>
            <table>
              {docs?.map((item) => (
                <tr>
                  <td key={item.id} className="name-document">
                    <span className="break">{item.titre}</span>
                  </td>
                  <td className="a-icon">
                    <a
                      onClick={() => downloadFile(item.path, item.titre)}
                      target="_blank"
                    >
                      {" "}
                      <i className="fas fa-download " />
                    </a>
                    <a href={item.path} target="_blank">
                      {" "}
                      <i className="fas fa-eye " />
                    </a>
                    <a onClick={() => removeFile(item)} target="_blank">
                      {" "}
                      <i className="fas fa-trash " />
                    </a>
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <form className="formRegister" onSubmit={handleSubmit}>
            {successMsg ? (
              <div className="successMsg">
                <p>
                  {successMsg} <i className="fa-solid fa-check green"></i>
                </p>
              </div>
            ) : errMsg ? (
              <div className="errorMsg">
                <p>
                  {errMsg} <i class="fa-solid fa-xmark red"></i>
                </p>
                <p className="err">
                  Veuillez respecter la taille et le format exigé
                </p>
              </div>
            ) : successMsgRem ? (
              <div className="successMsg">
                <p>
                  {successMsgRem} <i className="fa-solid fa-check green"></i>
                </p>
              </div>
            ) : (
              <p></p>
            )}

            <h4>Charger un nouveau document</h4>
            <label>
              Titre:
              <div className="">
                <input
                  type="text"
                  id="titre"
                  name="titre"
                  value={titre}
                  onChange={(e) => setTitre(e.target.value)}
                  autoComplete="off"
                  accept="application/pdf"
                  required
                />
              </div>
            </label>
            <label>
              Document format PDF:
              <div className="">
                <input
                  type="file"
                  id="doc"
                  name="doc"
                  ref={inputRef}
                  defaultValue={doc}
                  onChange={(e) => setDoc(e.target.files[0])}
                  autoComplete="off"
                  accept="application/pdf"
                  required
                />
              </div>
            </label>

            {showBtn ? (
              <div className="spinner-check">
                {" "}
                <img src={spinner} alt="logo2" />
              </div>
            ) : (
              <button disabled={activeBtn} type="submit">
                Charger
              </button>
            )}
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ProfilClient;

import axios from "axios";

//const BASE_URL = 'https://zz-courtage.dvpt.pulsweb.ch';
const BASE_URL = "https://localhost:8000";

export default axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: { "Content-Type": "application/json" },
  //withCredentials: true
});

import React, { useState } from "react";
import "../../styles/styles.css"

import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../api/axios";


const PASSWORD_URL = '/api/reset-password/new/password';
const NewPassword = () => {

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState('');

  const { setAuth, persist, setPersist } = useAuth();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [faClass, setFaClass] = useState("fa-regular fa-eye");
  const [faClass2, setFaClass2] = useState("fa-regular fa-eye");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      setFaClass("fa-regular fa-eye-slash")
      return;
    }
    setPasswordType("password")
    setFaClass("fa-regular fa-eye")
  }

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text")
      setFaClass2("fa-regular fa-eye-slash")
      return;
    }
    setPasswordType2("password")
    setFaClass2("fa-regular fa-eye")
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(PASSWORD_URL,
        JSON.stringify({ password, user: location.state.id })
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data.code === 203) {
        setAuth("");
        navigate("/login", { replace: true });

        setErrMsg("")
      }
      else {
        setErrMsg(response.data.message);
      }

    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Erreur serveur');
      }
    }


  }

  return (

    <>
      <div className="title-pwd-reset">
        <h1>Le nouveau mot de passe</h1>
        <p>Réinitialisez le mot de passe en deux étapes rapides</p>
      </div>

      <div className="form-container">
        <form className="formRegister" onSubmit={handleSubmit}>

          <label>
            Mot de passe:
            <div className="eye-position3" id='show_hide_password'>
              <input type={passwordType} value={password} onChange={(e) => setPassword(e.target.value)} className="" id="pwd" />
              <div className="eye">
                <a onClick={togglePassword} role="button" tabIndex="0"><i className={faClass}></i></a>

              </div>
            </div>

          </label>

          <label>
            Confirmer le mot de passe:
            <div className="eye-position3" id='show_hide_password2'>
              <input type={passwordType2} value={password2} onChange={(e) => setPassword2(e.target.value)} className="" id="pwd" />
              <div className="eye">
                <a onClick={togglePassword2} role="button" tabIndex="0"><i className={faClass2}></i></a>

              </div>
            </div>

          </label>

          <button type="submit">Réinitialisez le mot de passe</button>
        </form>
      </div>
    </>




  )
}

export default NewPassword
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Modal from "react-modal";
import spinner from "../images/loader.gif";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    OverflowY: "auto",
    maxHeight: "80vh",
    width: "70%",
    maxWidth: "735px",
  },
};
const DEMENAGE_URL = "/api/client/add/demenage";
const QUESTION_URL = "/api/client/add/question";
Modal.setAppElement("#root");
const SINISTRE_URL = "/api/client/sinistre";
const ProfilHeader = () => {
  const [users, setUsers] = useState();
  const [doc, setDoc] = useState();
  const [type, setType] = useState();
  const [message, setMessage] = useState("");
  const [date, setDate] = useState();
  const [ville, setVille] = useState("");
  const [postCode, setPostCode] = useState("");
  const [adresse, setAdresse] = useState();
  const [nouvelAdresse, setNouvelAdresse] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [contrats, setContrats] = useState();
  const [showBtn, setShowBtn] = useState(false);
  const [activeBtn, setActiveBtn] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [showBtn2, setShowBtn2] = useState(false);
  const [showBtn3, setShowBtn3] = useState(false);
  const [titre, setTitre] = useState("déménager");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowBtn(true);
    setActiveBtn("disabled");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("date", date);
    formData.append("ville", ville);
    formData.append("postCode", postCode);
    formData.append("adresse", adresse);
    formData.append("message", message);
    formData.append("pdf", doc);
    try {
      const response = await axiosPrivate.post(SINISTRE_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data.code === 203) {
        setSuccessMsg("La demande à été envoyée");
        setShowBtn(false);
        setActiveBtn("");
      }
      else{
        setShowBtn(false);
        setActiveBtn("");
        setErrMsg(response?.data.message);
      }
    } catch (err) {
      setShowBtn(false);
      setActiveBtn("");
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Erreur de chargement");
      }
      setShowBtn(false);
      setActiveBtn("");
    }
    /*  console.log(doc) */
  };

  function openModal() {
    setIsOpen(true);
    setSuccessMsg("");
  }

  function openModal2(item) {
    console.log(item);
    if (item == "demenage") setTitre("déménager");
    else setTitre("une question");
    setIsOpen2(true);
    setAdresse("");
    setDate(""); 
    setNouvelAdresse("");
    setSuccessMsg("");
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setSuccessMsg("");
  }
  function afterOpenModal2() {
    subtitle.style.color = "#f00";
  }

  function closeModal2() {
    setIsOpen2(false);
    setSuccessMsg("");
    setMessage("");
    setAdresse("");
    setDate(""); 
    setNouvelAdresse("");
  }

  const handleSubmitDemenage = async (e) => {
    e.preventDefault();

    if (!message) setErrMsg("Le champ message est vide");
    else
      try {
        setShowBtn2(true);
        setActiveBtn("disabled");
        const response = await axiosPrivate.post(
          titre == "déménager" ? DEMENAGE_URL : QUESTION_URL,
          JSON.stringify({ message: message, date : date, adresse : adresse, nouvel_adresse : nouvelAdresse }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        /*  console.log(JSON.stringify(response?.data)); */
        if (response?.data.code === 203) {
          setSuccessMsg("La demande à été envoyée");
          setShowBtn2(false);
          setActiveBtn("");
        }
      } catch (err) {
        setShowBtn2(false);
        setActiveBtn("");
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Erreur serveur");
        }
      }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    let toolTip = document.getElementById("tooltip");

    window.addEventListener("mousemove", toolTipXY);

    function toolTipXY(e) {
      let x = e.clientX,
        y = e.clientY;
      toolTip.style.top = y + 20 + "px";
      toolTip.style.left = x + 20 + "px";
    }

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/api/client/profil");

        /* console.log(response.data); */
        isMounted && setUsers(response.data.data);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getUsers();
    const getContrat = async () => {
      try {
        const response = await axiosPrivate.get("/api/client/contrats");
        /* console.log(response.data); */
        isMounted && setContrats(response?.data?.data);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getContrat();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <div className="container">
        <div className="welcome">
          <h3 className="hh">
            Bienvenue, {users?.nom} {users?.prenom}
          </h3>
        </div>

        <div className="buttons">
          <button onClick={openModal} className="btn1">
            Déclarer un sinistre
          </button>
          <button onClick={() => openModal2("demenage")} className="btn1">
            Je déménage
          </button>
          <button onClick={() => openModal2("question")} className="btn1">
            J'ai une question
          </button>
          <div className="link-profil shadow-effect">
            <Link to="/profil">
              {" "}
              <img src="Icons/mon-profil.svg" alt="" />{" "}
            </Link>
            <Link id="tooltip" className="link-profil--link" to="/profil">
              Mon profil
            </Link>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Déclarer un sinistre à ZZ Courtage
          </h2>
          <button className="close" onClick={closeModal}>
            X
          </button>
        </div>
        {successMsg ? (
          <div className="successMsg">
            <p>
              {successMsg} <i className="fa-solid fa-check green"></i>
            </p>
          </div>
        ) : (
          <form className="formRegister" onSubmit={handleSubmit}>
            <label>
              Nom:
              <div className="">
                <input
                  type="text"
                  id="nom"
                  autoComplete="off"
                  defaultValue={users?.nom}
                  readOnly
                  required
                />
              </div>
            </label>
            <label>
              Prénom:
              <div className="">
                <input
                  type="text"
                  id="prenim"
                  autoComplete="off"
                  defaultValue={users?.prenom}
                  readOnly
                  required
                />
              </div>
            </label>
            <label>
              Adresse:
              <div className="">
                <input
                  type="text"
                  id="adresse"
                  autoComplete="off"
                  defaultValue={users?.adresse}
                  readOnly
                  required
                />
              </div>
            </label>
            <label>
              Code postal:
              <div className="">
                <input
                  type="text"
                  id="postcode"
                  autoComplete="off"
                  defaultValue={users?.codePostal}
                  readOnly
                  required
                />
              </div>
            </label>
            <label>
              Téléphone:
              <div className="">
                <input
                  type="text"
                  id="telephone"
                  autoComplete="off"
                  defaultValue={users?.telephone}
                  readOnly
                  required
                />
              </div>
            </label>
            <label>
              Adresse e-mail:
              <div className="">
                <input
                  type="email"
                  id="username"
                  autoComplete="off"
                  readOnly
                  defaultValue={users?.email}
                  required
                />
              </div>
            </label>
            <label>
              <span>
                Type de contrat <span className="red-color">*</span>:
              </span>
              <div className="">
                <select
                  required
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option value="">Choisir le contrat</option>
                  {contrats?.map((item) => (
                    <option value={item.type}>
                      {item.type}{" "}
                      {item.description ? "- " + item.description : ""}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <label>
              <span>
              Lieu du sinistre – Ville <span className="red-color">*</span>:
              </span>
              <div className="">
                <input
                  type="text"
                  id="date"
                  autoComplete="off"
                  onChange={(e) => setVille(e.target.value)}
                  required
                />
              </div>
            </label>
            <label>
              <span>
              Lieu du sinistre – code postal{" "}
               :
              </span>
              <div className="">
                <input
                  type="text"
                  id="date"
                  autoComplete="off"
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
            </label>
            <label>
            Lieu du sinistre - adresse :
              <div className="">
                <input
                  type="text"
                  id="date"
                  autoComplete="off"
                  onChange={(e) => setAdresse(e.target.value)}
                />
              </div>
            </label>
            <label>
              <span>
                Date du sinistre <span className="red-color">*</span>:
              </span>
              <div className="">
                <input
                  type="date"
                  id="date"
                  autoComplete="off"
                  onChange={(e) => setDate(e.target.value)}
                  required
                  max={currentDate}
                />
              </div>
            </label>
            <label>
              Documents liés au sinistre:
              <div className="input-wrapper">
                <input
                  type="file"
                  id="doc"
                  name="doc"
                  onChange={(e) => setDoc(e.target.files[0])}
                  autoComplete="off"
                  accept="application/pdf, application/msword"
                />
                {doc && (
                  <i
                    className="fas fa-check-circle"
                    style={{ color: "green" }}
                  ></i>
                )}
              </div>
            </label>
            <label>
              Message:
              <div className="">
                <textarea
                  id="message"
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  rows="5"
                  placeholder="Merci de décrire avec le plus de détails possible le sinistre, en donnant toutes les informations pertinentes tel que : Lieux, date, heure, personnes présentes, énoncés des évènements…"
                ></textarea>
              </div>
            </label>
            {showBtn ? (
              <div className="spinner-check">
                {" "}
                <img src={spinner} alt="logo2" />
              </div>
            ) : (
              <button disabled={activeBtn} type="submit">
                Envoyer
              </button>
            )}
          </form>
        )}
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Nous contacter pour {titre}
          </h2>
          <button className="close" onClick={closeModal2}>
            X
          </button>
        </div>
        {errMsg ? errMsg : ""}
        {successMsg ? (
          <div className="successMsg">
            <p>
              {successMsg} <i className="fa-solid fa-check green"></i>
            </p>
          </div>
        ) : (
          <form className="formRegister" onSubmit={handleSubmitDemenage}>
            <label>
              Adresse mail:
              <div className="">
                <input
                  type="email"
                  id="username"
                  autoComplete="off"
                  defaultValue={users?.email}
                  readOnly
                  required
                />
              </div>
            </label>
            {titre == "déménager" && (
              <>
                <label>
                  Ancienne adresse:
                  <div className="">
                    <input
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                      type="text"
                      id="username"
                      autoComplete="off"
                      required
                    />
                  </div>
                </label>
                <label>
                  Nouvelle adresse:
                  <div className="">
                    <input
                      value={nouvelAdresse}
                      onChange={(e) => setNouvelAdresse(e.target.value)}
                      type="text"
                      id="username"
                      autoComplete="off"
                      required
                    />
                  </div>
                </label>
                <label>
                  Depuis quand:
                  <div className="">
                    <input
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      type="date"
                      id="username"
                      autoComplete="off"
                      required
                    />
                  </div>
                </label>
              </>
            )}
            <label>
              Message:
              <div className="">
                <textarea
                  required
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="5"
                  placeholder={
                    titre == "déménager"
                      ? "Souhaitez-vous adapter quelque chose ? Avez-vous d’autres informations liées à ce déménagement ? Nous prendrons de toute façon contact avec vous pour en discuter."
                      : "Bonjour, j’aimerais vous demander …"
                  }
                ></textarea>
              </div>
            </label>
            {showBtn2 ? (
              <div className="spinner-check">
                {" "}
                <img src={spinner} alt="logo2" />
              </div>
            ) : (
              <button disabled={activeBtn} type="submit">
                Envoyer
              </button>
            )}
          </form>
        )}
      </Modal>
    </>
  );
};

export default ProfilHeader;

import React from 'react'
import { useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import "../../styles/styles.css";
import useAuth from '../../hooks/useAuth';
import logo from '../../Logos/Logo-zzcourtage.png';
import logo2 from '../../Logos/Logo-ZZfidu.png'
import $ from 'jquery';

const click = () => {
    $('.menu-bloc1').show();
    $('.menu-mobile-humberger').hide();
    $('.close-menu-mobile').show();
};
const close = () => {
    $('.menu-bloc1').hide();
    $('.menu-mobile-humberger').show();
    $('.close-menu-mobile').hide();
};
const Header = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth();

    const signOut = async () => {
         await logout();
        navigate('/login'); 
    }

    
    return (
        <>
            <header>
              <div className="container-header"> 
                <div className="top-blocs">
                    <div className='blocks-content'>
                    <div className="logo-bloc">
                        <div className="bloc-1">
                            <div className="logo1">
                                <a href="#"><img src={logo} alt="logo" /></a>
                            </div>
                        </div>
                        <div className="bloc-2">
                            <div className="logo2">
                                <a href="#"><img src={logo2} alt="logo2" /></a>
                            </div>

                        </div>
                        </div>
                        <div className="bloc-3">
                            <div className="social-icons">
                                <ul>
                                    <div className="div-i"><li><a href="https://www.linkedin.com/in/michael-zambon-5b9490115/" target="_blank"><i className="topIcon fa-brands fa-linkedin-in"></i></a></li></div>
                                    <div className="div-i"><li><a href="#" target="_blank"><i className="topIcon fa-brands fa-whatsapp"></i></a></li></div>
                                    <div className="div-i"><li><a href="mailto:info@zzcourtage.ch" target="_blank"><i className="topIcon fa-regular fa-envelope"></i></a></li></div>
                                    <div className="div-i"><li><a href="https://www.instagram.com/zzcourtage/" target="_blank"><i className="topIcon fa-brands fa-instagram"></i></a></li></div>
                                    {Object.values(auth)?.length > 0 &&
                                        <button className="log-out" onClick={signOut}><i class="fa-solid fa-arrow-right-from-bracket"></i>Déconnexion</button>
                                    }

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
              </div>  
                <nav>
                    <div className="menu-bloc1">
                    <div className="container-header">
                        <div className="nav-links">
                            <ul>
                                <li><a href="/">Accueil</a></li>
                                <li><a href="https://www.zzcourtage.ch/courtage-en-assurance" target='_blank'>Courtage en assurance</a></li>
                                <li><a href="https://www.zzcourtage.ch/fiduciaire" target='_blank'>Fiduciaire</a></li>
                                <li><a href="https://www.zzcourtage.ch/contact" target='_blank'>Contact</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="menu-bloc">
                        <div className="rv"><a href="https://www.zzcourtage.ch/contact" target='_blank'>Prendre rendez-vous</a></div>
                        <div className="menu-mobile-humberger"> <a href="#" onClick={click}><i className="topIcon fa-solid fa-bars menu-mobile"></i></a></div>
                        <div className="close-menu-mobile"> <a href="#" onClick={close}><i className="topIcon fa-solid fa-xmark close-menu-mobile-icon"></i></a></div>
                    </div>
                </nav>
              
            </header>
        </>
    )
}

export default Header
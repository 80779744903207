import { Link } from "react-router-dom"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Modal from 'react-modal';
import useAuth from "../hooks/useAuth";
import spinner from '../images/loader.gif'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        OverflowY: 'auto',
        maxHeight: '80vh',
        width: '70%',
        maxWidth: '735px'
    },
};
const MESSAGE_URL = '/api/client/change/profil'
const PASSWORD_URL = '/api/reset-password/new/password';
Modal.setAppElement('#root');
const Profil = () => {
    const [users, setUsers] = useState();
    const [message, setMessage] = useState()
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState('');
    const [errMsg2, setErrMsg2] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const { setAuth, persist, setPersist } = useAuth();
    const [showBtn, setShowBtn] = useState(false)
    const [showBtn2, setShowBtn2] = useState(false)
    const [activeBtn, setActiveBtn] = useState("")
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");
    const [faClass, setFaClass] = useState("fa-regular fa-eye");
    const [faClass2, setFaClass2] = useState("fa-regular fa-eye");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            setFaClass("fa-regular fa-eye-slash")
            return;
        }
        setPasswordType("password")
        setFaClass("fa-regular fa-eye")
    }

    const togglePassword2 = () => {
        if (passwordType2 === "password") {
            setPasswordType2("text")
            setFaClass2("fa-regular fa-eye-slash")
            return;
        }
        setPasswordType2("password")
        setFaClass2("fa-regular fa-eye")
    }


    const handleSubmitUser = (e) => {
        e.preventDefault();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowBtn(true)
        setActiveBtn("disabled")
        if (password != password2) {

            setErrMsg2('Les deux mots de passe ne sont pas identiques');
        }
        else
            try {
                setShowBtn(true)
                setActiveBtn("disabled")
                const response = await axiosPrivate.post(PASSWORD_URL,
                    JSON.stringify({ password, user: users?.email })
                );
                /*  console.log(JSON.stringify(response?.data)); */
                if (response?.data.code === 203) {
                    setAuth("");
                    setTimeout(() => {
                        navigate("/login", { replace: true });
                    }, 200);
                    setErrMsg2("Votre mot de passe a été changé")
                }
                else {
                    setErrMsg2(response.data.message);
                    setShowBtn(false)
                    setActiveBtn("")
                }

            } catch (err) {
                setShowBtn(false)
                setActiveBtn("")
                if (!err?.response) {
                    setErrMsg2('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg2('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg2('Unauthorized');
                } else {
                    setErrMsg2('Erreur serveur');
                }
            }


    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        if (!message)
            setErrMsg("Le champ message est vide")
        else
            try {
                setShowBtn2(true)
                setActiveBtn("disabled")
                const response = await axiosPrivate.post(MESSAGE_URL,
                    JSON.stringify({ message: message }),
                    {
                        headers: { 'Content-Type': 'application/json' },

                    }
                );
                /*  console.log(JSON.stringify(response?.data)); */
                if (response?.data.code === 203) {
                    setSuccessMsg("La demande à été envoyée")
                    setShowBtn2(false)
                    setActiveBtn("")
                }

            } catch (err) {
                setShowBtn2(false)
                setActiveBtn("")
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Erreur serveur');
                }
            }


    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {

        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setSuccessMsg("");
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/api/client/profil');
                /*  console.log(response.data); */
                isMounted && setUsers(response.data.data);
            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])
    return (
        <>
            <div className="container2">
                <Link to="/"><button className="custom-btn btn-5"><span><i className="topIcon fas fa-arrow-left"></i>   Home</span></button></Link>
            </div>
            <section className="profil-form">
                <h1>Mon Profil</h1>
                <form className="formRegister" onSubmit={handleSubmitUser}>
                    <label>
                        Nom:
                        <div className="">
                            <input type="text"
                                id="nom"
                                autoComplete="off"
                                defaultValue={users?.nom}
                                readOnly
                                required />
                        </div>
                    </label>
                    <label>
                        Prenom:
                        <div className="">
                            <input type="text"
                                id="prenim"
                                autoComplete="off"
                                defaultValue={users?.prenom}
                                readOnly
                                required />
                        </div>
                    </label>
                    <label>
                        Adresse:
                        <div className="">
                            <input type="text"
                                id="adresse"
                                autoComplete="off"
                                defaultValue={users?.adresse}
                                readOnly
                                required />
                        </div>
                    </label>
                    <label>
                        Code postal:
                        <div className="">
                            <input type="text"
                                id="postcode"
                                autoComplete="off"
                                defaultValue={users?.codePostal}
                                readOnly
                                required />
                        </div>
                    </label>
                    <label>
                        Telephone:
                        <div className="">
                            <input type="text"
                                id="telephone"
                                autoComplete="off"
                                defaultValue={users?.telephone}
                                readOnly
                                required />
                        </div>
                    </label>
                    <label>
                        Adresse mail:
                        <div className="">
                            <input type="email"
                                id="username"
                                autoComplete="off"
                                value={users?.email}
                                required />
                        </div>
                    </label>


                    <button type="submit" onClick={openModal}>Modifier mon profil<i class="fa-regular fa-envelope white"></i></button>
                </form>
                <h1>Mot de passe</h1>
                <br />
                {errMsg2 &&

                    <div className="errorMsg">

                        <p>{errMsg2} <i class="fa-solid fa-xmark red"></i></p>

                    </div>}


                <form className="formRegister" onSubmit={handleSubmit}>

                    <label>
                        Nouveau mot de passe:
                        <div className="eye-position" id="show_hide_password">
                            <input required type={passwordType} value={password} onChange={(e) => setPassword(e.target.value)} className="" id="pwd" />
                            <div className="eye">
                                <a onClick={togglePassword} role="button" tabIndex="0"><i className={faClass}></i></a>
                            </div>
                        </div>

                    </label>
                    <label>
                        Confirmer le mot de passe:
                        <div className="eye-position" id="show_hide_password">
                            <input required type={passwordType2} value={password2} onChange={(e) => setPassword2(e.target.value)} className="" id="pwd2" />
                            <div className="eye">
                                <a onClick={togglePassword2} role="button" tabIndex="0"><i className={faClass2}></i></a>
                            </div>
                        </div>

                    </label>

                    {showBtn ? <div className="spinner-check"> <img src={spinner} alt="logo2" /></div> : <button disabled={activeBtn} type="submit" >Modifier mon mot de passe<i class="fa-solid fa-lock white"></i></button>}
                </form>

            </section>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Nous contacter pour modifier votre profil</h2>
                    <button className="close" onClick={closeModal}>X</button>
                </div>
                {errMsg ? errMsg : ""}
                {successMsg ? <div className="successMsg"><p>{successMsg}  <i className="fa-solid fa-check green" ></i></p></div> :
                    < form className="formRegister" onSubmit={handleSubmitEdit}>

                        <label>
                            Adresse mail:
                            <div className="">
                                <input type="email"
                                    id="username"
                                    autoComplete="off"
                                    defaultValue={users?.email}
                                    readOnly
                                    required />
                            </div>
                        </label>
                        <label>
                            Message:
                            <div className="">
                                <textarea required id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)}
                                    rows="5" placeholder="Exemple :
                                    Bonjour ZZ courtage,
                                    Voici ma nouvelle adresse de domicile:
                                    Nom, prénom
                                    Adresse 1
                                    Code postale" >

                                </textarea>
                            </div>
                        </label>
                        {showBtn2 ? <div className="spinner-check"> <img src={spinner} alt="logo2" /></div> : <button disabled={activeBtn} type="submit" >Envoyer</button>}
                    </form>
                }

            </Modal>
        </>
    )
}

export default Profil

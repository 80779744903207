import { useNavigate,Link  } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <section>
            <div className="container2">
                <h1 className="Unauthorized--h1">Accés non autorisé</h1>
                <br />
                <p className="Unauthorized--p">Vous n'êtez pas autorisé d'accéder à cette page</p>
                <div className="flexGrow">
                    <button onClick={goBack} className="custom-btn btn-5"><span><i className="topIcon fas fa-arrow-left"></i>  Retour</span></button>

                </div>
            </div>
        </section>
    )
}

export default Unauthorized

import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Profil from './components/Profil';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import NewPassword from './components/newPassword/NewPassword';
import ProfilClient from './components/ProfilClient';
import ResetPassword from './components/ResetPassword';
import ResetSMS from './components/ResetSMS';
import Validation from './components/validation/Validation';



const ROLES = {
  'User': "ROLE_USER",
  'Editor': "ROLE_USER",
  'Admin': "ROLE_SUPER_ADMIN"
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}> 
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="login/:id" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="reset/password" element={<ResetPassword />} />
        <Route path="reset/password/:id" element={<ResetSMS />} />
        <Route path="/new/password" element={<NewPassword />} />
        <Route path="/validation" element={<Validation />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<Home />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
            <Route path="profil" element={<Profil />} />
          </Route>


          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="admin" element={<Admin />} />
            
            <Route path="admin/profil/client/:id" element={<ProfilClient />} />
          </Route>

        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import axios from '../api/axios';
const RESET_URL = '/api/reset-password';


const ResetPassword = () => {

    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();
    const params = useParams();
    const [user, setUser] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    
  
  

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault();
            try {
                const response = await axios.post(RESET_URL,
                    JSON.stringify({ email: user }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        
                    }
                );
                /* console.log(JSON.stringify(response?.data)); */
                if(response?.data?.success)
                {
                    setSuccessMsg("un mail de réinitialisation de mot de mot de passe a été envoyé");
                    setUser("");
                }

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Erreur de connexion au serveur');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Email ');
                } else if (err.response?.status === 401) {
                    setErrMsg('Adresse email invalide');
                } else {
                    setErrMsg('Erreur serveur');
                }
                errRef.current.focus();
            }

    }

    

    

    return (

        <>
            <div className="form-container">


                <div className="logo-connexion">
                    

                    <h1>Mot de passe perdu</h1>
                    <p>Réinitialisez le mot de passe en deux étapes rapides</p>

                    {errMsg ? <p className='error'>{errMsg}</p> : ""}
                </div>


                <form className="formRegister" onSubmit={handleSubmit}>
                    <label>
                        Adresse mail:
                        <div className="">
                            <input type="email"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required />
                        </div>

                    </label>
                    
                    
                    <button type="submit" >Envoyer</button>
                    {successMsg ? <p className='success'>{successMsg}</p> : ""}
                </form>
            </div>
        </>

    )
}

export default ResetPassword

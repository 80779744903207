import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import axios from '../api/axios';
import logo from '../Logos/ZZ.png';
const LOGIN_URL = '/api/login_check';
const CHECK_URL = '/api/client/check';

const Login = () => {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();
    const params = useParams();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [passwordType, setPasswordType] = useState("password");
    const [faClass, setFaClass] = useState("fa-regular fa-eye");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            setFaClass("fa-regular fa-eye-slash")
            return;
        }
        setPasswordType("password")
        setFaClass("fa-regular fa-eye")
    }

    useEffect(() => {
        userRef.current?.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

       /*  console.log("params", params.id); */

        if (params.id != undefined) {
            try {
                const response = await axios.post(CHECK_URL,
                    JSON.stringify({ email: user, token: params.id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                       
                    }
                );
                if (JSON.stringify(response?.data?.code == 203)) {
                    try {
                        const response = await axios.post(LOGIN_URL,
                            JSON.stringify({ username: user, password: pwd }),
                            {
                                headers: { 'Content-Type': 'application/json' },
                                
                            }
                        );
                       /*  console.log(JSON.stringify(response?.data)); */
                        /* const accessToken = response?.data?.token;
                        const roles = response?.data?.data?.roles;
                        const refresh = response?.data?.refresh_token;
                        const active = response?.data?.data?.active;
                        localStorage.setItem("refreshToken", response?.data?.refresh_token);
                        localStorage.setItem("accessToken", response?.data?.token); */
                        /* console.log(accessToken) */
                        /* setAuth({ user, pwd, roles, accessToken, refresh, active }); */
                        setUser('');
                        setPwd('');
                        navigate("/validation", {
                             state: {
                                id: user
                            } 
                        },);
                    } catch (err) {
                        if (!err?.response) {
                            setErrMsg('Erreur de connexion au serveur');
                        } else if (err.response?.status === 400) {
                            setErrMsg('Missing Username or Password');
                        } else if (err.response?.status === 401) {
                            setErrMsg('Adresse email ou mot de passe invalide');
                        } else {
                            setErrMsg('Erreur serveur');
                        }
                        errRef.current?.focus();
                    }
                }

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Erreur de connexion au serveur');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Adresse email ou mot de passe invalide');
                } else {
                    setErrMsg('Erreur serveur');
                }
                errRef.current?.focus();
            }

        }

        else {
            try {
                const response = await axios.post(LOGIN_URL,
                    JSON.stringify({ username: user, password: pwd }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        
                    }
                );
                if(response?.data?.data?.active){
                    const accessToken = response?.data?.token;
                    const roles = response?.data?.data?.roles;
                    const refresh = response?.data?.refresh_token;
                    const active = response?.data?.data?.active;
                    localStorage.setItem("refreshToken", response?.data?.refresh_token);
                    localStorage.setItem("accessToken", response?.data?.token);
                   /*  console.log(accessToken) */
                    setAuth({ user, pwd, roles, accessToken, refresh, active });
                    setUser('');
                    setPwd('');
                    const redirect = roles.filter(element => {
                        return element === "ROLE_SUPER_ADMIN"
                    })
    
                    if (redirect[0] === "ROLE_SUPER_ADMIN")
                        navigate("/admin", { replace: true })
                    else
                        navigate("/", { replace: true })
                }
                else
                setErrMsg('Votre compte n\'est pas actif');

            } catch (err) {
                if (!err?.response) {
                    setErrMsg('Erreur de connexion au serveur');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Adresse email ou mot de passe invalide');
                } else {
                    setErrMsg('Erreur serveur');
                }
                errRef.current?.focus();
            }
        }


    }

    const togglePersist = () => {
        /* console.log("params", params.id); */
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])



    return (

        <>
            <div className="form-container">


                <div className="logo-connexion">
                    <div className="logo-circle">
                        <img src={logo} alt="logo" />
                    </div>

                    <h1>Connexion </h1>
                    {params.id ? <p>Saisissez les informations reçues par email</p> : <p>Veuillez vous connecter</p>}

                    {errMsg ? <p className='error'>{errMsg}</p> : ""}
                </div>


                <form className="formRegister" onSubmit={handleSubmit}>
                    <label>
                        Adresse mail:
                        <div className="">
                            <input type="email"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required />
                        </div>

                    </label>
                    <label>
                        Mot de passe:
                        <div className="eye-position" id='show_hide_password'>
                            <input type={passwordType}
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required className="btn-bottom" id="pwd" />
                            <div className="eye">
                                <a onClick={togglePassword} role="button" tabIndex="0"><i className={faClass}></i></a>
                            </div>

                        </div>

                    </label>
                    {!params.id && <Link to={"/reset/password"}>J'ai oublié mon mot de passe </Link>}
                    <div className="persistCheck">
                        <input
                            type="checkbox"
                            id="persist"
                            onChange={togglePersist}
                            checked={persist}
                        />

                        <label htmlFor="persist">Se souvenir de moi</label>
                    </div>
                    <button type="submit" > {params.id ? "Créer un compte" : "Se connecter"}</button>
                
                </form>
            </div>
        </>

    )
}

export default Login

import  React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import logo3 from '../Logos/icon-i.png';
import ReactPaginate from "react-paginate";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        OverflowY: 'auto',
        maxHeight: '80vh',
        width: '70%',
        maxWidth: '735px'
    },
};
Modal.setAppElement('#root');
const Contrat = () => {
    const [contrats, setContrats] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [contrat, setContrat] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [docs, setDocs] = useState([]);
    let subtitle;

    function openModal() {
        setIsOpen(true);
    }
    const downloadFile = (url, title) => {
        const titre = title+'.pdf';
        saveAs(url, titre)
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
   
        const getContrat = async () => {
            try {
                const response = await axiosPrivate.get('/api/client/contrats');
                /* console.log(response.data); */
                isMounted && setContrats(response?.data?.data);
            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getContrat();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])
    const [currentPage, setCurrentPage] = useState(0);

    const PER_PAGE = 5;
    const offset = currentPage * PER_PAGE;
    const currentPageData = contrats
        ?.slice(offset, offset + PER_PAGE)
        ?.map((item) => (
            <tr key={item.id}>
            <td>{item.assureur}</td>
            <td className="info-assurance" >{item.type} <span className="info"  style={{ display: item.description ? "inline-block" : "none" }}><img src={logo3} alt="logo2" /><span className="description">{item.description}</span></span></td>
            <td><i className="fa fa-download download" aria-hidden="true" style={{ marginRight: '5px', color: item?.document?.length > 0 ? "#008000" : "#FF0000" }} ></i><a onClick={() => { setDocs(item); openModal(); setContrat(item.id) }} href="#" style={{ marginRight: '5px', color: item?.document?.length > 0 ? "#008000" : "#FF0000" }} >Télécharger le pdf</a> </td>
            </tr>
        ));
    const pageCount = Math.ceil(contrats?.length / PER_PAGE);
    
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }
    return (

        <>
        <div className="container-header table-admin">
            <table className='tableau'>

                <thead>
                    <tr>
                        <th>Nom assureur</th>
                        <th>Type d'assurance</th>
                        <th>Téléchargement</th>

                    </tr>
                </thead>
                <tbody>
                {currentPageData}
                </tbody>
            </table>
        </div>
            <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={"→"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
            <div className="modal">
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"

                >
                    <div>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Documents</h2>
                        <button className="close" onClick={closeModal}>X</button>
                    </div>
                    <div className="formRegister">
                        <h4>Liste des documents</h4>
                        <table>
                            {docs?.document?.map((item) => (

                                <tr>
                                    <td key={item.id} className="name-document">
                                        <span className='break'>{item.titre}</span>
                                    </td>
                                    <td className='a-icon'><a onClick={() => downloadFile(item.path, item.titre)} target="_blank"> <i className="fas fa-download " /></a>
                                        <a href={item.path} target="_blank"> <i className="fas fa-eye " /></a></td>
                                </tr>

                            ))}

                        </table>
                    </div>


                </Modal>
            </div>
        </>
    );
};

export default Contrat;

import { useNavigate, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import Contrat from "./Contrat";
import ProfilHeader from "./ProfilHeader";

const Home = () => {
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    return (
        <section>
            <ProfilHeader />
            <div className="container2">
                    <h2 className='titre'>Mes contrats</h2>
                    <Contrat />
                </div>
        </section>
    )
}

export default Home

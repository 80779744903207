import React from 'react';
import "../../styles/styles.css"
import useAuth from '../../hooks/useAuth';

const Footer = () => {
    const { auth } = useAuth();
    return (
        <>

            <footer>
                <div className='footer'>
                
                    <div id='footer-content'>
                    {Object.values(auth)?.length == 0 &&
                            <div className='section-top'>
                                <a href="https://www.zzcourtage.ch/contact" target='_blank'>Prendre rendez-vous</a>
                            </div>
                        }
                      <div className="container-header">
                       
                        <div className='section-middle'>
                            <div className="footer-about">
                                <h2>À propos de nous</h2>
                                <p>Le groupe Z propose aux entreprises et particuliers
                                    une gestion administrative globale autant pour la partie
                                    assurances que pour la partie fiduciaire.</p>
                            </div>
                            <div className="footer-address">
                                <h2>ZZ Courtage</h2>
                                <p>+41 21 552 75 60</p>
                                <p>info@zzcourtage.ch</p>
                                <p>Av. du Grey 58, 1018 Lausanne, Suisse</p>
                            </div>
                            <div className="footer-address">
                                <h2>ZZ fidu</h2>
                                <p>+41 21 552 75 60</p>
                                <p>info@zzfidu.ch</p>
                                <p>Av. du Grey 58, 1018 Lausanne, Suisse</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className='section-bottom'>
                            <p>© 2023 ZZ Courtage et ZZ fidu | Tous droits réservés | Site internet par ZIP.ch SA</p>
                    </div>
                </div>

                </div>

            </footer>


        </>
    );
};

export default Footer;
import { Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";


const Admin = () => {
    return (
        <>
           <AdminHeader />
            <br />
            <div className="flexGrow container2">
            </div>
            
            </>
    )
}

export default Admin

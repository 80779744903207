import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        /* console.log("Refresh", localStorage.getItem("refreshToken"), "Token", localStorage.getItem("accessToken")) */
        const response = await axios.post('/api/token/refresh',  JSON.stringify({
            refresh_token: localStorage.getItem("refreshToken"),
            token: localStorage.getItem("accessToken")
          }), {
            headers:{
                'Content-Type': 'application/json'

            }
            
        });
        setAuth(prev => {
            /* console.log(JSON.stringify(prev));
            console.log(response.data.token); */
            return {
                ...prev,
                roles: response?.data?.data?.roles,
                accessToken: response.data.token
            }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;
